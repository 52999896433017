/* App.css */
body {
    margin: 0;
    padding: 0;
}

.App {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(45deg, #000000, #FF0500, #FFD700);
    /* Use the gradient of black, red, and gold */
    font-family: 'Roboto', sans-serif;
    /* Use 'Roboto' or any other elegant font for the rest of the text */
    color: white;
    /* Set the text color to white */
    text-align: center;
}

.App-header {
    font-family: 'Montserrat', sans-serif;
    /* Use 'Montserrat' or any other elegant font for the header */
    font-weight: 700;
    /* Use bold font for the header */
    font-size: 48px;
    margin-bottom: 20px;
}

h1 {
    font-size: 48px;
    margin-bottom: 20px;
}

.countdown {
    display: flex;
    justify-content: center;
    margin-top: 30px;
}

.countdown-item {
    margin: 0 10px;
}

.countdown-item span {
    display: block;
    font-size: 36px;
}

.countdown-item span:last-child {
    font-size: 18px;
}

.social-icons {
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.social-icons a {
    margin: 0 15px;
    color: #FFD700;
    /* Use gold as the social icon color */
    font-size: 36px;
    text-decoration: none;
    transition: opacity 0.3s ease;
}

.social-icons a:hover {
    opacity: 0.7;
}

/* App.css */
.countdown-item {
    margin: 0 10px;
    animation: scaleUp 0.5s ease-in-out;
}

@keyframes scaleUp {
    0% {
        transform: scale(0.5);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}